// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
html, body, #root {
    margin: 0px;
    padding: 0px;
    height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/App.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;AAChB","sourcesContent":["\r\nhtml, body, #root {\r\n    margin: 0px;\r\n    padding: 0px;\r\n    height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
