
const style = {
    mainContainer: {
        backgroundColor: '#8d8d8d59',
        borderRadius: 29,
        color: 'white',
        padding: '20px',
        width: '28%',
        position: 'relative',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
};

export default style;
